<template>
  <div class="page">
    <top-bar/>
    <div class="view has-bar">
      <div class="view-box">
        <div class="view-box-top">
          <img src="../assets/imgmain/line1.png" alt="">
        </div>
        <div class="view-box-center">
          <div class="text">
            {{txt}}
          </div>
          <img src="../assets/imgmain/line2.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../api'
export default {
  data () {
    return {
      txt: ''
    }
  },
  methods: {
    getInfo() {
      api.get('report/result/summary', { serial_no: this.$route.query.no }).then(res => {
        if (res) {
          this.txt = res.data.VerdictName
        }
      })
    }
  },
  created () {
    this.getInfo()
  }
}
</script>

<style lang="less" scoped>
.view {
  // height: 100%;
  background: url("../assets/imgmain/bgview.png");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  padding-top: 46px;
  display: flex;
  justify-content: center;
  overflow: auto;
  .view-box {
    position: relative;
    width: 698px;
    &-top {
      position: absolute;
      width: 100%;
      top: 0;
      z-index: 0;
      img {
        width: 100%;
      }
    }
    &-center {
      position: absolute;
      width: 100%;
      padding-bottom: 46px;
      top: 16px;
      z-index: 10;
      .text {
        width: 648px;
        padding: 45px;
        margin: 0 auto;
        min-height: 200px;
        background-color: #fff;
        white-space: pre-wrap;
        text-align: justify;
        word-break: break-all;
        line-height: 1.4;
        color: #00af67;
      }
      img {
        display: block;
        margin: 0 auto;
        width: 648px;
      }
    }
  }
}
</style>
